const navConfig = [
  {
    href: '/#product',
    text: 'Product',
    moveToId: 'product',
  },
  {
    href: '/#learn',
    text: 'Learn',
    moveToId: 'learn',
  },
  {
    href: '/#installation',
    text: 'Installation',
    moveToId: 'installation',
  }
];

export { navConfig };
