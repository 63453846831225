const MobileScanSectionConfig = [
  {
    image: "mobile-scan-8",
    title: "AntiSpy Setup",
    desc: "Data security can be a tough one to understand, but we'll make it make sense! Follow our easy custom-made checklists to maximize your device protection.",
    labels: {
      android: false,
      comingSoon: false
    }
  },
  {
    image: "mobile-scan-1",
    title: "Spyware Scan",
    desc: "With his 20/20 vision, Kiko sees suspicious apps a mile away and will make sure no one has unauthorized access to your data.",
    labels: {
      android: true,
      comingSoon: false
    }
  },
  {
    image: "mobile-scan-2",
    title: "Unlock catcher",
    desc: "Find out who tried to unlock your phone and see pictures of their face. Get alerted every time there’s and unsuccessful unlock attempt.",
    labels: {
      android: true,
      comingSoon: true
    }
  },
  {
    image: "mobile-scan-3",
    title: "Web protection",
    desc: "See whether links contain potential threats like viruses or damaged/expired security protocols on the websites.",
    labels: {
      android: false,
      comingSoon: true
    }
  },
  {
    image: "mobile-scan-4",
    title: "Hidden camera detector",
    desc: "Detect any hidden cameras in a hotel room, office or at home using our built-in scanner.",
    labels: {
      android: false,
      comingSoon: true
    }
  },
  {
    image: "mobile-scan-5",
    title: "Virtual location",
    desc: "Change the location of your device to hide your actual whereabouts when browsing the internet or using apps.\n",
    labels: {
      android: false,
      comingSoon: true
    }
  },
  {
    image: "mobile-scan-6",
    title: "Ad blocker",
    desc: "Block all ads in the web browser.",
    labels: {
      android: false,
      comingSoon: true
    }
  },
  {
    image: "mobile-scan-7",
    title: "Wi-Fi Scan",
    desc: "Just like spotting a predator in the wild, Kiko will scan your Wi-Fi for any lurking threats and alert you immediately if something's off.",
    labels: {
      android: false,
      comingSoon: true
    }
  },
];

export { MobileScanSectionConfig };
