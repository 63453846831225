const faqConfig = [
  {
    question: 'Do you offer a free trial?',
    answer: 'At the moment, we don’t offer a free trial. To get started, you’ll need to choose one of our subscriptions. Our subscription options are designed to cater to various needs and budgets, ensuring that you find the perfect fit for your requirements. By subscribing, you\'ll be able to take advantage of our comprehensive tools and support right away. If you have any questions about our plans or need assistance in choosing the right one, please don\'t hesitate to contact our Support team.',
  },
  {
    question: 'Can I use KikoBear on my computer?',
    answer: 'KikoBear is currently available for mobile phones only. We\'ve packed it with features to safeguard your digital life on the go. You can download it directly from the app store.',
  },
];

export { faqConfig };
