import React from "react";
import {Helmet} from "react-helmet-async";

import styles from "./NotFound.module.scss";
import cn from "classnames";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page not found</title>
        <meta name="description" content="404 Page not found" />
      </Helmet>
      <div className={styles["page-404"]}>
        <div className={cn("wrapper", styles["page-404__wrapper"])}>
          <div className={styles["page-404__content"]}>
            <h2 className={"main-title"}>
              <span>404</span> Page not found
            </h2>
            <img
              className={styles["page-404__image"]}
              src="/images/home/hero.svg"
              alt="KikoBear hero"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
