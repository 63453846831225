import React from "react";
import { Button } from "../UI";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import Lottie from "lottie-react";
import { SecurityCtaConfig } from "./config";
import { animationData, animationDataMob } from "./animation";

import cn from "classnames";
import styles from "./SecurityCtaSection.module.scss";

const SecurityCtaSection = () => {
  const isMobile = useMediaQuery("(max-width: 530px)");
  const animation = isMobile ? animationDataMob : animationData;

  return (
    <section className={styles["security-cta"]} id="product">
      <div className={cn("wrapper", styles["security-cta__wrapper"])}>
        <div className={styles["security-cta__container"]}>
          <div className={styles["security-cta__text-section"]}>
            <h2 className={cn(styles["security-cta__title"], "main-title")}>
              No Threat Can Bear Us
            </h2>
            <p
              className={cn(styles["security-cta__subtitle"], "main-subtitle")}
            >
              Step confidently into the digital wilderness with KikoBear by your side. Focusing on security, ease of use, and innovation, we empower you to fight back against cyber threats to ensure your privacy is always safe.
            </p>
            <div className={styles["security-cta__items"]}>
              {SecurityCtaConfig.map(({ image, title }, i) => {
                return (
                  <div
                    className={styles["security-cta__item"]}
                    key={`features-item-${i}`}
                  >
                    <img src={`/images/home/${image}`} alt={`feature-${i}`} />
                    <h3>{title}</h3>
                  </div>
                );
              })}
            </div>
            <Button
              className={styles["security-cta__button"]}
              href="#"
              rel="nofollow"
              external={"true"}
            >
              Try now!
            </Button>
          </div>
          <div className={styles["security-cta__image-wrapper"]}>
            <picture>
              <source
                srcSet="/images/home/feature-Pixel_mob.png"
                media="(max-width: 991px)"
              />
              <img
                src="/images/home/feature-Pixel.png"
                alt="iPhone screen"
                width="444"
                height="925"
              />
            </picture>
            <Lottie
              className={cn(styles["security-cta__decor"], "only-mobile")}
              animationData={animation}
              loop={true}
            />
          </div>
        </div>
      </div>
      <Lottie
        className={cn(styles["security-cta__decor"], "only-desktop")}
        animationData={animation}
        loop={true}
      />
    </section>
  );
};

export default SecurityCtaSection;